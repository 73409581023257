import React from 'react';
import styled from 'styled-components';
import LoaderGIF from '../../../../static/loader.gif';

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999999;
  background: ${({ theme }) => theme.background};
`;

const Loader = () => {
  return (
    <StyledWrapper>
      <img src={LoaderGIF} />
    </StyledWrapper>
  );
};

export default Loader;
