import React, {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { GlobalStyles } from '@theme/globalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '@theme/theme';
import Navigation from '@components/organisms/Navigation/Navigation';
import '@theme/typography.css';
import Footer from '@components/organisms/Footer/Footer';
import Context from '@context';
import { useWindowSize } from 'react-use';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import Cookie from '@components/atoms/Cookie/Cookie';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Loader from '@components/molecules/Loader/Loader';

export const BasicTemplate: FC<Props> = ({
  children,
  header,
  className,
  setWidth,
  withoutNavigation,
  title,
  description,
}) => {
  const { width, height } = useWindowSize();
  const { datoCmsSite }: any = useStaticQuery(query);

  const [canBeDisplayedCookie, setCanBeDisplayedCookie] = useState(false);
  const [canBeDisplayedMessenger, setCanBeDisplayedMessenger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (setWidth) {
      setWidth(width);
    }
  }, [width, setWidth]);

  useEffect(() => {
    setTimeout(() => setCanBeDisplayedMessenger(true), 1000);
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      setTimeout(() => {
        AOS.init({
          easing: 'ease-out-cubic',
          duration: 1000,
          anchorPlacement: 'center-center',
          once: true,
        });
      }, 1500);
    }
  });

  return (
    <>
      <Helmet>
        <title>
          {title ? `Diamentowe Zacisze | ${title}` : 'Diamentowe Zacisze'}
        </title>
        {/* <meta
          name="description"
          content={datoCmsSite.globalSeo.fallbackSeo.description}
        /> */}
        <html lang="pl" />
        {/* <meta
          property="og:image"
          content={datoCmsSite.globalSeo.fallbackSeo.image.fixed.src}
        />
        <meta
          property="og:title"
          content={`${datoCmsSite.globalSeo.fallbackSeo.title}${
            title && ` | ${title}`
          }`}
        />
        <meta
          property="og:description"
          content={datoCmsSite.globalSeo.fallbackSeo.description}
        /> */}
      </Helmet>

      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Context.Provider value={{ width, height }}>
          <header>
            {!withoutNavigation && <Navigation />}
            {header && header()}
          </header>
          <main className={className}>{children}</main>
          <Footer />
          {isLoading && <Loader />}
          <Cookie
            canBeDisplayed={canBeDisplayedCookie}
            setCanBeDisplayed={setCanBeDisplayedCookie}
          />

          {/* <MessengerCustomerChat
            appId={process.env.GATSBY_FACEBOOK_APP_ID}
            pageId="111398774101039"
            language="pl_PL"
          /> */}
        </Context.Provider>
      </ThemeProvider>
    </>
  );
};

const query = graphql`
  {
    datoCmsSite {
      globalSeo {
        fallbackSeo {
          title
          description
          image {
            fixed(width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`;

interface Props {
  header?: () => ReactNode;
  className?: string;
  setWidth?: (width: number) => void;
  withoutNavigation?: boolean;
  title?: string;
  description?: string;
}
